<template>
	<div class="ClueReportInfo">
		<w-navTab titleText="反映须知"></w-navTab>
		<div class="ClueReportInfo-head">
			<img v-if="isCity()" src="@/assets/img/icon52.png" alt="" />
			<img v-else src="@/assets/img/icon96.png" alt="" />
		</div>
		<div class="ClueReportInfo-body">
			<div class="body">
				<div class="body-text">
					本模块主要功能为信息收集，只要平安义警在上饶市任何地点发现任何违法犯罪线索或者重大公共安全隐患信息，就可以利用本模块向警方报告情况，以利于警方能够更好的打击违法犯罪、维护社会治安、服务人民群众，共同守护平安上饶。对于积极提供有价值线索信息的，警方将给予积分奖励，当积分累积到一定数量可用于兑换警方提供的相应礼品。对于提供有效举报线索得以消除重大公共安全隐患、协助侦破重大案件、抓获违法犯罪人员的，警方将及时给予表彰奖励。
				</div>
				<!-- <div class="body-title">一、特别提醒</div>
				<div class="body-text">1.本模块不具备警情实时处置和反馈功能，遇即时、紧急或重大情况的，请直接拨打１１０报警电话，切勿使用本平台报警。</div>
				<div class="body-text">2.与公安机关职责范围无关的事项，请通过12345政务服务便民热线等渠道向政府有关职能部门反映。</div> -->
				<div class="body-title">一、信息收集范围</div>
				<div class="body-text">1.上饶市范围内的各类违法犯罪线索。</div>
				<div class="body-text">2.上饶市范围内可能危害公共安全的隐患信息。</div>
				<div class="body-title">二、九种常见的线索信息</div>
				<div class="body-text"><span>1.涉恶涉暴类：</span>高利放贷、娱乐消费、工程建筑等引发的聚众斗殴、非法拘禁、寻衅滋事等涉恶违法犯罪线索；为非作歹、称霸一方，欺压群众严重危害社会等违法犯罪线索；有扬言报复社会、购买刀具、汽油等行为，可能会出现杀人、放火、抢劫等严重后果，异常动向的行为偏执、心理失衡、悲观厌世的极端暴力倾向人员和易肇事肇祸精神病人。</div>
				<div class="body-text"><span>2.偷盗诈骗类：</span>可能实施盗窃、诈骗、抢劫、抢夺等侵财违法犯罪的人员，如没有固定工作的人员，突然一夜暴富，身边出现来历不明的汽车、金器、高档手机、手提电脑、电动车等贵重物品，与其合法收入明显不符的人员。</div>
				<div class="body-text"><span>3.治安违法类：</span>涉及枪支弹药、危爆物品、卖淫嫖娼聚众赌博等违法犯罪行为；非法加工食品、药品等行为，如黑作坊加工地沟油、死猪肉、死禽等行为；外国人非法就业、非法居留、非法入境等情况，特别是在低端劳动密集型企业中有语言不通、情况异常的外来人员。</div>
				<div class="body-text"><span>4.毒品犯罪类：</span>走私、贩卖、运输、制造、非法持有毒品，引诱、教唆、欺骗他人吸毒、强迫他人吸毒、容留他人吸毒等行为线索。如携带可疑锡箔纸、插有吸管的饮料瓶、白色粉未等疑似毒品情形；或发现吸毒过量产生幻觉、脱衣、自残等异常行为的人员。</div>
				<div class="body-text"><span>5.交通管理类：</span>超载、毒驾、酒驾等严重影响交通安全、可能造成重大安全隐患的交通违法行为，严重影响交通安全、可能造成重大安全隐患的交通信号灯、标志标线、道路交通设施故障破损、设置不合理等情形。</div>
				<div class="body-text"><span>6.消防隐患类：</span>人员密集公共场所等存在堵塞疏散通道、安全出口、消防车通道等情况；城中村、棚户区、居民小区存在重大火灾安全隐患等情况，企业存在厂房、仓库、居住场所等“三合一”违规情况。</div>
				<div class="body-text"><span>7.经济犯罪类：</span>群众反映强烈的非法吸收公众存款、非法传销、制假售假、集资诈骗等严重经济犯罪；造成重大国家经济损失的虚开发票、骗取贷款、高利转贷等违法犯罪线索。</div>
				<div class="body-text"><span>8.网络犯罪类：</span>利用网络进行网上赌博、招嫖、诈骗非法经营、造谣诽谤、勾结串联、煽动滋事、贩枪贩毒、侵害公民个人信息、散布恐怖有害信息等违法犯罪行为。</div>
				<div class="body-text"><span>9.涉恐涉稳类等其他情形类：</span>涉恐涉稳等其他违法犯罪线索或者重大公共安全隐患信息。</div>
				<div class="body-title">三、特别提醒</div>
				<div class="body-text">1.本模块不具备警情实时处置和反馈功能，紧急情况或重大事项的，请直接拨打110报警电话，切勿使用本平台报警。</div>
			</div>
		</div>
		
		<div class="ClueReportInfo-footer">
			<div class="footer-button" v-if="time == 0" @click="toClueReport">前往反映</div>
			<div class="footer-button" v-else>我已阅读（{{time}}秒）</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ClueReportInfo',
	inject: ['themeColor', 'className', 'isCity', 'isDistrict'],
	data() {
		return {
			time: 10,
		};
	},
	created() {
		let timeText = setInterval(()=>{
			let time = this.time
			time--
			this.time = time
			if(this.time == 0){
				clearTimeout(timeText)
			}
		},1000)
		
	},
	methods: {
		toClueReport(){
			this.$router.push({
				name: 'ClueReport'
			});
		}
	}
};
</script>

<style scoped lang="less">
	.ClueReportInfo.city{
		.ClueReportInfo-footer{
			.footer-button{
				background: #3377FF;
			}
		}
	}
	
	.ClueReportInfo.district{
		.ClueReportInfo-footer{
			.footer-button{
				background: #FF6969;
			}
		}
	}


.ClueReportInfo {
	width: 100%;
	min-height: 100vh;
	padding-bottom: 65px;
	box-sizing: border-box;
	.ClueReportInfo-head {
		width: 100%;
		height: 230px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.ClueReportInfo-body {
		margin: 0 8px;
		background: #fff;
		border-radius: 6px;
		margin-top: -87px;
		position: relative;
		z-index: 5;
		.body {
			padding: 12px;
			.body-title {
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				color: #1c1d1d;
				margin-top: 16px;
				&.body-title:first-child {
					margin-top: 0;
				}
			}

			.body-text {
				font-size: 15px;
				line-height: 22px;
				text-align: justify;
				text-indent: 28px;
				color: #44484e;
				margin-top: 8px;
				span{
					font-weight: bold;
				}
			}
		}
	}
	.ClueReportInfo-footer {
		position: fixed;
		bottom: 12px;
		left: 0;
		padding: 0 8px;
		width: 100%;
		box-sizing: border-box;
		z-index: 10;
		.footer-button {
			font-weight: bold;
			font-size: 15px;
			line-height: 22px;
			text-align: center;
			padding: 11px;
			color: #fff;
			background: linear-gradient(92.48deg, #3377FF 0%, #3377FF 100%);
			box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
			border-radius: 4px;
		}
	}
}
</style>
